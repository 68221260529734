import { API_PREFIX } from '../consts';
import { DELETE, GET, POST, PUT } from '../utils/request';

export function getAlternativePayments() {
  return GET(`${API_PREFIX}/v2/alternative_payments`);
}

export function createAlternativePayments(payload) {
  return POST(`${API_PREFIX}/v1/alternative_payment`, payload);
}

export function batchUpdateAlternativePayments(payload) {
  return PUT(`${API_PREFIX}/v2/alternative_payments`, payload);
}

export function deleteAlternativePayments(id) {
  return DELETE(`${API_PREFIX}/v1/alternative_payment/${id}`);
}
