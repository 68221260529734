import { batchUpdateAlternativePayments, getAlternativePayments } from '../services/alternativePayments';

const BASE = 'ALTERNATIVE_PAYMENTS';

export const actions = {
  loadingAlternativePayments: `${BASE}_LOADING_ALTERNATIVE_PAYMENTS`,
  loadedAlternativePayments: `${BASE}_LOADED_ALTERNATIVE_PAYMENTS`,
};

export const setAlternativePayments = (payments) => async (dispatch) => {
  dispatch({
    type: actions.loadedAlternativePayments,
    payload: payments,
  });
};

export const queryAlternativePayments = (callback) => async (dispatch, getState) => {
  let response;

  const {
    alternativePayments: { alternativePayments },
  } = getState();

  if (alternativePayments.length) {
    typeof callback === 'function' && callback(alternativePayments);
    return;
  }

  dispatch({ type: actions.loadingAlternativePayments });

  try {
    response = await getAlternativePayments();
  } catch (e) {
    response = { success: false };
  }

  const payments = response.success ? response.data || [] : [];
  payments.sort((a, b) => a.sequence - b.sequence);
  dispatch(setAlternativePayments(payments));

  typeof callback === 'function' && callback(payments);
};

export const doUpdateAlternativePayments = (payload) => async (dispatch) => {
  let response;

  try {
    response = await batchUpdateAlternativePayments(payload);
  } catch (e) {
    response = { success: false };
  }

  if (response.success) {
    dispatch(setAlternativePayments(payload));
  }
};
