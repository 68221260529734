const env = process.env.STAGING_ENV || 'staging-02';
export const pushAppKeys = {
  'staging-01': {
    key: 'dd23c571d68777847d2a',
    cluster: 'us2',
  },
  'staging-02': {
    key: '4511753e1c6ccb8c4a13',
    cluster: 'us2',
  },
  'staging-03': {
    key: '856cc095e2f2966fa8ff',
    cluster: 'ap1',
  },
  production: {
    key: '01ca590969e67eb01faf',
    cluster: 'us2',
  },
}[env];

export const cdsPushAppKeys = {
  'staging-01': {
    key: 'dd23c571d68777847d2a',
    cluster: 'us2',
  },
  'staging-02': {
    key: 'dd23c571d68777847d2a',
    cluster: 'us2',
  },
  'staging-03': {
    key: 'dd23c571d68777847d2a',
    cluster: 'ap1',
  },
  production: {
    key: '01ca590969e67eb01faf',
    cluster: 'us2',
  },
}[env];

export const ADMIN_AUTH_CLIENT_ID = {
  'staging-01': 'qjEXccR7O-FHYCV0l5JDrXIteOHqz077rXo-AupqAzg',
  'staging-02': 'F9_o1efOgGK7Tv-tyfUrXmWGOJK7-24cc-2gFKeQ3lg',
  'staging-03': 'qjEXccR7O-FHYCV0l5JDrXIteOHqz077rXo-AupqAzg',
  production: '9Gm19dT7acAqOUA9bb3ZjMu-83TSOHVw0hhRc-g5JM0',
}[env];

export const CPS_AUTH_CLIENT_ID = {
  'staging-01': '6SqudCZibCcRjahRgSM11AeObHNLXtoKFV5e4ltU38M',
  'staging-02': '6SqudCZibCcRjahRgSM11AeObHNLXtoKFV5e4ltU38M',
  'staging-03': '6SqudCZibCcRjahRgSM11AeObHNLXtoKFV5e4ltU38M',
  production: 'kPudPCfmSKA_uyY54h1igbIx6VA_3Y__86fpQUaO_3M',
}[env];

export const POS_AUTH_CLIENT_ID = {
  'staging-01': '9R4-unR_m9fsMVOthY6VAZzLQCZWvc5UToET6FX30RA',
  'staging-02': '9R4-unR_m9fsMVOthY6VAZzLQCZWvc5UToET6FX30RA',
  'staging-03': '9R4-unR_m9fsMVOthY6VAZzLQCZWvc5UToET6FX30RA',
  production: 'fAvE1dH-mjV5Zf5kyWx5dddHOb7XE0eB3aPBDSdNZ5E',
}[env];

export const mixpanelToken =
  env === 'production' ? '49c957035a6d59a602d3f07dd2900d36' : 'ec687e80efb22b1297feb89bbf082e61';

export const qrCodeServiceDomain = {
  demo: 'https://qrcode-staging-01.chowbus.com/',
  'staging-01': 'https://qrcode-staging-01.chowbus.com/',
  'staging-02': 'https://qrcode-staging-02.chowbus.com/',
  'staging-03': 'https://qrcode-staging-03.chowbus.com/',
  production: 'https://qrcode.chowbus.com/',
}[env];

export const BILLING_SETTLEMENT_PREFIX = {
  'staging-01': 'https://billing-settlement-staging-01.chowbus.com',
  'staging-02': 'https://billing-settlement-staging-02.chowbus.com',
  'staging-03': 'https://billing-settlement-staging-03.chowbus.com',
  production: 'https://billing-settlement.chowbus.com',
}[env];

export const BILLING_REPORT_API_PREFIX = {
  'staging-01': 'https://billing-report-staging-01.chowbus.com',
  'staging-02': 'https://billing-report-staging-02.chowbus.com',
  'staging-03': 'https://billing-report-staging-03.chowbus.com',
  production: 'https://billing-report.chowbus.com',
}[env];

export const BILLING_PAYMENT_API_PREFIX = {
  'staging-01': 'https://payment-api-staging-01.chowbus.com',
  'staging-02': 'https://payment-api-staging-02.chowbus.com',
  'staging-03': 'https://payment-api-staging-03.chowbus.com',
  production: 'https://payment-api.chowbus.com',
}[env];

export const ONLINE_ORDERING_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/online-ordering',
  'staging-02': 'https://api-staging-02.chowbus.com/online-ordering',
  'staging-03': 'https://api-staging-03.chowbus.com/online-ordering',
  production: 'https://api.chowbus.com/online-ordering',
}[env];

export const SMS_SERVICE_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/sms-service',
  'staging-02': 'https://api-staging-02.chowbus.com/sms-service',
  'staging-03': 'https://api-staging-03.chowbus.com/sms-service',
  production: 'https://api.chowbus.com/sms-service',
}[env];

export const CATALOG_SERVICE_API_PREFIX = {
  'staging-01': 'https://api-staging-01.chowbus.com/compendium',
  'staging-02': 'https://api-staging-02.chowbus.com/compendium',
  'staging-03': 'https://api-staging-03.chowbus.com/compendium',
  production: 'https://api.chowbus.com/compendium',
}[env];

export const SENTRY_DSN = 'https://edff1711871b478ab6d6234413b4518a@o199370.ingest.sentry.io/5904247';

export const BASIC_AUTH = 'cG9zX2RldmVsb3BlcjpmWDVuQ2lpa2pqRUp5VjB3UmR2YjNpMDU=';

export const POS_CUSTOMER = 'POS Customer';
export const POS_VISITOR = 'POS Visitor';

export const TABLET_ORDERING_WEBPAGE_DOMAIN = {
  'staging-01': 'https://qrcode-tablet-staging-01.chowbus.com',
  'staging-02': 'https://qrcode-tablet-staging-02.chowbus.com',
  'staging-03': 'https://qrcode-tablet-staging-03.chowbus.com',
  production: 'https://qrcode-tablet.chowbus.com',
}[env];

export const BILLING_REPORT_BASIC_AUTH = {
  'staging-01': 'YmlsbGluZ19yZXBvcnQ6ZmFrZV9jcmVkZW50aWFsX2JpbGxpbmdfcmVwb3J0X3RvX3Bvcw==',
  'staging-02': 'YmlsbGluZ19yZXBvcnQ6ZmFrZV9jcmVkZW50aWFsX2JpbGxpbmdfcmVwb3J0X3RvX3Bvcw==',
  'staging-03': 'YmlsbGluZ19yZXBvcnQ6ZmFrZV9jcmVkZW50aWFsX2JpbGxpbmdfcmVwb3J0X3RvX3Bvcw==',
  production: 'YmlsbGluZ19yZXBvcnQ6Nzc5YTYwMTBmMjYwNjE3YTBiYWRkYTA4ZjE5ZTBkMGY=',
}[env];
