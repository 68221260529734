import JwtDecode from 'jwt-decode';
import { getUserInfo } from './utils';
import { getSessionItem, USER_KEYS } from '../store/storage';
import { IDENTITY_ADMIN, IDENTITY_RESTAURATEUR } from '../consts/user';

const IDENTITY_KEY = 'identity';

export function checkIsSelf(employeeId) {
  return getUserInfo().sub === employeeId;
}

export function getEmployeeName(employee, fullName = false) {
  if (!employee) return null;
  const { first_name, last_name } = employee;
  const firstName = first_name || '';
  const lastName = last_name || '';
  if (fullName) return `${firstName} ${lastName}`;
  return `${firstName} ${lastName.slice(0, 1)}`;
}

export function extractRestaurantId(data) {
  const userKeys = Object.keys(data);
  const identityKey = userKeys.find((key) => key.includes(IDENTITY_KEY));
  let restaurantId = '';
  let identity;

  if (identityKey) {
    identity = data[identityKey];

    if (identity === IDENTITY_ADMIN) {
      const restaurantIdKey = userKeys.find((key) => key.includes('restaurant_id'));
      if (restaurantIdKey) restaurantId = data[restaurantIdKey];
    } else if (identity === IDENTITY_RESTAURATEUR) {
      const scopeKey = userKeys.find((key) => key.includes('scope'));
      if (scopeKey) restaurantId = data[scopeKey].split(':')[1];
    }
  }

  return { identity, restaurantId };
}

export function getCurrentUserInfoFromToken(token) {
  const decoded = JwtDecode(token);
  const { expires_in = 1800, email = '', exp } = decoded;
  const expireTime = (exp ? exp * 1000 : new Date().getTime()) + (expires_in - 30) * 1000;
  const { restaurantId, identity } = extractRestaurantId(decoded);
  return {
    currentUser: decoded,
    currentRestaurantId: restaurantId,
    email,
    expireTime,
    identity,
  };
}

export function getCurrentUserInfoFromSession() {
  let currentUserInfo;

  try {
    currentUserInfo = JSON.parse(getSessionItem(USER_KEYS.userInfo) || '{}');
  } catch (e) {
    currentUserInfo = {};
  }

  return currentUserInfo;
}

/***
 * checkNavMenuVisible 判断某个导航菜单是否可见
 * @param params.menu {object} 导航菜单
 * @param params.restaurantInfo {object} 餐厅信息
 */
export function checkNavMenuVisible({ menu, restaurantInfo }) {
  const { distributionMode, visibleChecker } = menu;
  let visible = true;

  if (typeof visibleChecker === 'function') {
    visible = visibleChecker({ restaurantInfo });
  } else if (distributionMode) {
    const { restaurant_contracts } = restaurantInfo;
    const modes = (restaurant_contracts || []).map((_) => _.distribution_mode);
    visible = modes.includes(distributionMode);
  }

  return visible;
}
