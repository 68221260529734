import axios from 'axios';
import { message as MessageBox } from 'antd';
import qs from 'qs';
import dataFormatter from './dataFormatter';
import { getSessionItem, KEYS } from '../store/storage';
import { LOCALE_EN } from '../consts';
import { modalMethods } from '../components/POSModal';
import requestManager from './requestManager';
import { refreshTokenClient } from './refreshUserToken';
import { GROUP_COMMON } from 'src/consts/enum';
import { logout } from '../actions/user';

const { ETIMEDOUT, ERR_NETWORK, ERR_BAD_RESPONSE, ERR_BAD_REQUEST } = axios.AxiosError;
const CONTENT_TYPE_JSON_API = 'application/vnd.api+json';
const CONTENT_TYPE_JSON = 'application/json';
const CONTENT_TYPE_TEXT = 'text/plain';
const isServer = typeof window === 'undefined';
const CONTENT_TYPE_CSV = 'text/csv';
const bracketParamFields = ['distribution_modes', 'distribution_mode', 'menu_ids', 'category_ids'];

const languages = {
  en: 'en',
  zh: 'zh-Hans',
};

// redux store
let reduxStore = null;

export const setStore = (store) => {
  reduxStore = store;
};

const instance = axios.create({
  baseURL: '',
  timeout: 60 * 1000,
  withCredentials: false,
  paramsSerializer: {
    serialize: function (params) {
      if (bracketParamFields.find((field) => field in params)) {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      }
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  },
});

function shouldShowErrorToast({ config, statusCode }) {
  const { silence, silentStatusCodes } = config;
  if (silence) return false;
  return !silentStatusCodes?.includes(statusCode);
}

instance.interceptors.request.use(
  async (config) => {
    if (requestManager.allCanceled) {
      throw new Error();
    }

    const { method, headers, url, showLoading = false, noNeedLogin } = config;

    if (showLoading) {
      modalMethods.showLoading({ messageId: 'fetching-data' });
    }

    let requestUrl = url;
    if (method.toUpperCase() === 'GET') {
      const [rawUrl, search] = requestUrl.split('?') || [];
      const query = qs.parse(search || '');
      query.ts = new Date().getTime();
      const q = qs.stringify(query);
      requestUrl = `${rawUrl}?${q}`;
    }
    config.url = requestUrl;

    if (!noNeedLogin && !headers.Authorization) {
      try {
        await refreshTokenClient.beforeRequest(reduxStore);
        const token = getSessionItem(KEYS.token) || '';
        headers.Authorization = `Bearer ${token}`;
      } catch (e) {
        return Promise.reject(e);
      }
    }

    let regionRoute = GROUP_COMMON;
    if (reduxStore) {
      const { app, user } = reduxStore.getState();
      if (app) {
        const { allAppConfigs = [] } = app;
        const configItem = allAppConfigs.find((config) => {
          const { restaurant_ids } = config;
          return (restaurant_ids || []).includes(user?.currentRestaurantId);
        });
        regionRoute = configItem ? configItem.name : GROUP_COMMON;
        if (regionRoute === 'RTM_CPS') regionRoute = 'alpha';
      }
    }
    headers['X-CHOWBUS-REGION-ROUTE'] = regionRoute;

    const locale = getSessionItem(KEYS.locale) || LOCALE_EN;
    headers.language = languages[locale];
    config.headers = headers;
    const controller = new AbortController();
    config.signal = controller.signal;
    requestManager.addRequestCache(requestUrl, controller);
    config.timeoutErrorMessage = locale === LOCALE_EN ? 'Request Timeout' : '请求超时';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function request(config = {}) {
  return instance
    .request(config)
    .then((response) => {
      const { config, headers, data, status } = response;
      let contentType = headers['content-type'] || CONTENT_TYPE_JSON;
      const { showLoading, url, parseAsJSONApi, parseAsRaw } = config;

      requestManager.removeRequestCache(url);

      if (requestManager.allCanceled) return { success: false };
      if (showLoading) modalMethods.hideLoading();

      if (parseAsJSONApi) {
        contentType = CONTENT_TYPE_JSON_API;
      } else if (contentType.includes(CONTENT_TYPE_JSON_API) && parseAsRaw) {
        contentType = CONTENT_TYPE_JSON;
      }

      let json;

      if (contentType.includes(CONTENT_TYPE_CSV)) {
        json = new Blob(['\ufeff' + data], { type: contentType });
      } else if (contentType.includes(CONTENT_TYPE_JSON) || contentType.includes(CONTENT_TYPE_TEXT)) {
        json = data;
      } else {
        try {
          json = dataFormatter.deserialize(data);
        } catch (error) {
          json = data;
        }
      }

      const shouldShowError = shouldShowErrorToast({ config, statusCode: status });

      if (!isServer && shouldShowError && data?.meta && data.meta) {
        const { user_messages } = data.meta;
        if (user_messages) MessageBox.success(user_messages.join(' '));
      }

      return {
        success: true,
        data: json,
        meta: data?.meta,
      };
    })
    .catch(async (error) => {
      if (!error || !axios.isAxiosError(error)) {
        return { success: false };
      }

      if (axios.isCancel(error) || requestManager.allCanceled) {
        return { success: false };
      }

      let shouldShowError = true;
      const { response, code } = error;

      if (response) {
        const { status, config } = response;
        shouldShowError = shouldShowErrorToast({ config, statusCode: status });
      }

      if (code && ![ERR_BAD_REQUEST, ERR_BAD_RESPONSE].includes(code)) {
        if (shouldShowError) {
          let message = 'Request Failed';
          if (code === ERR_NETWORK && (isServer || !navigator.onLine)) {
            message = 'Network Error';
          } else if (code === ETIMEDOUT) {
            message = 'Request Timeout';
          }
          MessageBox.error(message);
        }
        return { success: false };
      }

      if (!response) {
        if (!isServer) MessageBox.error(error.message || 'Unknown Error');
        return { success: false };
      }

      const { status, config, data } = response;
      const { url, showLoading } = config;

      requestManager.removeRequestCache(url);

      if (showLoading) {
        modalMethods.hideLoading();
      }

      if (status === 401) {
        const originalRequest = error.config;
        const retryTimes = originalRequest.retryTimes || 1;
        if (retryTimes >= 3) {
          if (reduxStore) reduxStore.dispatch(logout());
          return { success: false };
        }

        try {
          const userInfo = await refreshTokenClient.refreshTokenAfter401(reduxStore);
          const { token_type, access_token } = userInfo;
          const token = `${token_type} ${access_token}`;
          return await request({
            ...originalRequest,
            headers: { ...originalRequest.headers, Authorization: token },
            retryTimes: retryTimes + 1,
          });
        } catch (e) {
          return { success: false };
        }
      }

      if (!isServer && shouldShowError && data.message) {
        MessageBox.error(data.message);
      }

      if (!isServer && shouldShowError && data.errors) {
        MessageBox.error((data.errors || []).map((_) => _.title || _.detail).join(' '));
      }

      if (!isServer && shouldShowError && data.error_description) {
        MessageBox.error(data.error_description);
      }

      return { success: false, errors: data.errors || data.error || data, statusCode: status };
    });
}

export function POST(url, data = {}, config = {}) {
  return request({
    ...config,
    method: 'post',
    url,
    data,
  });
}

export function GET(url, params = {}, config = {}) {
  return request({
    ...config,
    method: 'get',
    url,
    params,
  });
}

export function DELETE(url, data = {}, config = {}) {
  return request({
    ...config,
    method: 'delete',
    url,
    data,
  });
}

export function PUT(url, data = {}, config = {}) {
  return request({
    ...config,
    method: 'put',
    url,
    data,
  });
}

export function PATCH(url, data = {}, config = {}) {
  return request({
    ...config,
    method: 'patch',
    url,
    data,
  });
}

export async function retryRequest(promiseGenerator, retryCount = 2) {
  if (typeof promiseGenerator !== 'function') return { success: false };
  let promise = promiseGenerator();
  if (!(promise instanceof Promise)) return { success: false };

  let _retryCount = 0;
  let response;

  while (_retryCount < retryCount) {
    response = await Promise.resolve(promise);

    if (response.success) {
      _retryCount = retryCount;
    } else {
      _retryCount += 1;
      promise = promiseGenerator();
    }
  }

  return response;
}
