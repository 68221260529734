import base64url from 'base64url';
import 'webcrypto-shim';
import { getPassJwk } from 'src/services/passJwk';
import { KEYS, getCookie, setCookie } from 'src/store/storage';

const encryptWithJwk = async (jwk, data) => {
  const key = await crypto.subtle.importKey('jwk', jwk, { name: 'RSA-OAEP', hash: { name: 'SHA-1' } }, true, [
    'encrypt',
  ]);
  const dataBuffer = new TextEncoder().encode(JSON.stringify(data));
  const encryptedData = await crypto.subtle.encrypt({ name: 'RSA-OAEP', hash: { name: 'SHA-1' } }, key, dataBuffer);
  const base64urlData = base64url.encode(encryptedData);
  return base64urlData;
};

export const encrypt = async (data) => {
  let passJwk;
  try {
    passJwk = getCookie(KEYS.passJwk);
    if (!passJwk) {
      passJwk = await getPassJwk();
      if (passJwk) {
        setCookie({ [KEYS.passJwk]: passJwk });
      }
    } else {
      passJwk = JSON.parse(passJwk);
    }
  } catch (error) {
    console.error('Error during get pass jwk:', error);
  }
  try {
    const encryptedData = await encryptWithJwk(passJwk, data);
    return { encrypted_params: encryptedData, jwk_kid: passJwk.kid };
  } catch (error) {
    console.error('Error during encryption:', error);
  }
};
