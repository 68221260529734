import router from 'next/router';
import { loginByEmailAndPassword } from '../services/login';
import { queryRgUserRole } from '../services/permission';
import { getRestaurantGroup } from '../services/restaurantGroup';
import { message as MessageBox } from 'antd';

import {
  setSessionItem,
  KEYS,
  getSessionItem,
  setCookie,
  removeSessionItem,
  deleteCookie,
  getCookie,
} from '../store/storage';
import requestManager from '../utils/requestManager';
import { RG_ROUTES } from '../consts/rgRoutes';
import { rgMenus, allRGPermissions } from '../consts/rgMenu';
import { clearAppSessions, setIsGroup } from './app';
import { actions as userActions, clearUserSession, cacheCurrentUserInfo, handleAuthorData } from './user';
import { actions as rgUserActions } from './rgUser';
import { setAlternativePayments } from './alternativePayments';

const BASE = 'RESTARAUNT_GROUP_USER';

export const actions = {
  cacheRgId: `${BASE}_CACHE_RG_ID`,

  clearAllCachedData: `${BASE}_CLEAR_CACHED_DATA`,

  cacheRgPermissions: `${BASE}_CACHE_RG_PERMISSIONS`,

  owningRestaurants: `${BASE}_OWNEING_RESTAURANTS`,
};

const loginPath = '/user/login';

const redirectToFromUrl = (allGroupIds) => async (dispatch) => {
  const { from = RG_ROUTES.report_earnings, currentRgId } = router.query;
  let jumpPath = from;

  let targetGroupId = '';
  if (currentRgId && allGroupIds.includes(currentRgId)) {
    targetGroupId = currentRgId;
  } else {
    targetGroupId = allGroupIds[0];
  }

  if (!jumpPath.includes('restaurant-group')) {
    jumpPath = RG_ROUTES.report_earnings;
  }

  dispatch(setCurrentRgId(targetGroupId));

  router.replace(jumpPath);
};

export const loginByPassword = (params, callback) => async () => {
  let response;
  try {
    response = await loginByEmailAndPassword(params);
  } catch (e) {
    response = { success: false };
  }
  typeof callback === 'function' && callback(response);
};

export const handleSessionData = (payload) => async (dispatch) => {
  const { user, restaurantId, identity } = handleAuthorData(payload);

  dispatch(
    cacheCurrentUserInfo({
      currentUser: user,
      currentRestaurantId: restaurantId,
      email: user.email,
      identity,
    })
  );
};

const handleLoginResponse = (response, callback) => async (dispatch, getState) => {
  const locale = getState().app.locale;
  if (!response.success) {
    dispatch({
      type: userActions.loginFailed,
    });
  } else {
    const data = response.data || {};
    dispatch(handleSessionData(data));
    const groupIds = await getGroups(); // ['1', '12', '37']
    if (groupIds.length === 0) {
      dispatch(clearUserSession());
      dispatch({
        type: userActions.loginFailed,
      });
      MessageBox.error(
        locale === 'zh'
          ? '没有找到餐厅组信息，请联系管理员开通'
          : 'No restaurant group found, please contact your administrator.'
      );
    } else {
      dispatch({ type: userActions.loginSuccess });
      dispatch(setIsGroup(true));
      dispatch(redirectToFromUrl(groupIds));
    }
  }
  typeof callback === 'function' && callback(response);
};

const getGroups = async () => {
  let response;

  try {
    response = await getRestaurantGroup();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) return [];
  const data = response?.data?.data || [];
  return Array.isArray(data) && data.length ? data.sort() : [];
};

export const rgLogin = (params, callback) => async (dispatch) => {
  dispatch({
    type: userActions.login,
  });

  dispatch(
    loginByPassword(params, (response) => {
      dispatch(handleLoginResponse(response, callback));
    })
  );
};

export const rgLogout = () => async (dispatch, getState) => {
  const currentRgId = getState()?.rgUser?.currentRgId || '';
  requestManager.cancelAll();
  dispatch(clearUserSession());
  dispatch(clearAppSessions());
  setAlternativePayments([]);
  router.replace({
    pathname: loginPath,
    query: {
      currentRgId,
    },
  });
};

export const loadMenus = () => async (dispatch, getState) => {
  const { user } = getState();

  let menus = user.menus;
  let restaurantGroupLoyaltyDealContract = user.restaurantGroupDealContract;

  if (user.loadingMenu || (menus && menus.length)) return;

  dispatch({
    type: userActions.loadingMenus,
  });

  let response;

  try {
    response = await queryRgUserRole();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: rgUserActions.cacheRgPermissions,
      payload: new Map(),
    });
    dispatch({
      type: userActions.loadingMenuFailed,
    });
    return;
  }

  let result = response.data;
  const restaurantGroups = result?.data?.restaurant_groups || [];
  const roles = result?.include?.roles || [];

  const groupIdPermissionMap = restaurantGroups.reduce((mData, group) => {
    const groupId = group.id + '';
    const groupRoles = group.roles || [];

    let curPermission = [];
    groupRoles.forEach((role) => {
      const rolePermissionItem = roles.find((r) => r.name === role);
      if (rolePermissionItem) {
        // permission codes in rgPermissionCodes is valid
        const permissions = (rolePermissionItem.permissions || []).filter((per) => allRGPermissions.includes(per));
        curPermission = [...new Set([...permissions, ...curPermission])];
      }
    });

    if (restaurantGroupLoyaltyDealContract) {
      curPermission.push('monolith:rg_loyalty_points_report:read');
      curPermission.push('monolith:rg_loyalty_members_report:read');
    }
    mData.set(groupId, curPermission);
    return mData;
  }, new Map());

  dispatch({
    type: rgUserActions.cacheRgPermissions,
    payload: groupIdPermissionMap,
  });

  const currentRgId = getSessionItem(KEYS.currentRgId) || getCookie(KEYS.currentRgId);
  dispatch(setCurrentPermissions({ groupIdPermissionMap, currentRgId }));
};

export const setCurrentPermissions =
  ({ groupIdPermissionMap, currentRgId }) =>
  async (dispatch, getState) => {
    const { rgUser } = getState();

    const permissionMaps = !groupIdPermissionMap ? rgUser.rgPermissions || new Map() : groupIdPermissionMap;

    const currentRgIdPermission = permissionMaps.get(currentRgId) || [];

    const curMenus = [];
    rgMenus.forEach((menu) => {
      const { permission_code, children } = menu;

      if (permission_code && currentRgIdPermission.includes(permission_code)) {
        curMenus.push(menu);
        return;
      }

      if (children && children.length) {
        const curChildren = [];

        children.forEach((child) => {
          const { permission_code } = child;
          if (permission_code && currentRgIdPermission.includes(permission_code)) {
            curChildren.push(child);
          }
        });
        if (curChildren.length) {
          curMenus.push({
            ...menu,
            children: curChildren,
          });
        }
      }
    });
    dispatch({
      type: userActions.loadingMenuSuccess,
      payload: curMenus,
    });
  };
export const clearRgAllCachedData = () => async (dispatch) => {
  dispatch({ type: actions.clearAllCachedData });
};

export const setCurrentRgId = (id) => async (dispatch) => {
  if (!id) {
    removeSessionItem(KEYS.currentRgId);
    deleteCookie(KEYS.currentRgId);
  } else {
    setSessionItem({
      [KEYS.currentRgId]: id,
    });
    setCookie({
      [KEYS.currentRgId]: id,
    });
  }

  dispatch({
    type: actions.cacheRgId,
    payload: id,
  });
};
