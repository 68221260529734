import { GET, POST, PUT, DELETE } from '../utils/request';
import { API_PREFIX, AUTH_API_PREFIX } from '../consts';
import { POS_AUTH_CLIENT_ID } from 'src/consts/third_party';
import { encrypt } from 'src/utils/encrypt';

export function queryRestaurantEmployees() {
  return GET(`${API_PREFIX}/v1/employees`);
}

export function queryMembersByRoleId(roleId) {
  return GET(`${API_PREFIX}/v1/employees/role/${roleId}`);
}

export function queryRestaurantEmployeeDetail({ userId }) {
  return GET(`${API_PREFIX}/v1/employee/${userId}`);
}

export async function createRestaurantEmployee({ password, pin, ...data }) {
  const encryptedData = await encrypt({ password, pin });
  return POST(
    `${API_PREFIX}/v1/employee`,
    { ...data, ...encryptedData },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID } }
  );
}

export async function updateRestaurantEmployee({ id, pin, ...data }) {
  const { hold_pin } = data;
  let encryptedData;
  if (!hold_pin) {
    encryptedData = await encrypt({ pin });
  }
  return PUT(
    `${API_PREFIX}/v1/employee/${id}`,
    { ...data, ...encryptedData },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID } }
  );
}

export function deleteEmployee(userId) {
  return DELETE(`${API_PREFIX}/v1/employee/${userId}`);
}

export async function activateRestaurantEmployee({ id, pin, ...data }) {
  const encryptedData = await encrypt({ pin });
  return PUT(
    `${AUTH_API_PREFIX}/api/v1/restaurateurs/${id}`,
    { ...data, ...encryptedData, active: true },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID } }
  );
}

export function deactivateRestaurantEmployee(id) {
  return PUT(
    `${AUTH_API_PREFIX}/api/v1/restaurateurs/${id}`,
    { active: false },
    { headers: { 'client-id': POS_AUTH_CLIENT_ID } }
  );
}

export function getEmployeesByDepartmentId(departmentId) {
  return GET(`${API_PREFIX}/v1/employees/department/${departmentId}`);
}

export function getUnassignedDepartmentEmployees() {
  return GET(`${API_PREFIX}/v1/employees/department/unassign`);
}

export function getOwnedRestaruants(userId) {
  return GET(`${API_PREFIX}/v1/employee/${userId}/restaurants`);
}
